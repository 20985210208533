@import "mixins";

.NotFoundScreen {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(181, 188, 203, 0.56);

    .HistoryScreenCont {
        width: 100%;
        @include container;
    }

    h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        /* identical to box height */

        color: #1d1d1b;
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        /* identical to box height */

        color: #1d1d1b;


        margin: 10px 0;
    }

    a {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        /* identical to box height */

        color: #3b85f7;
    }

    ul {
        li {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;

            color: #1d1d1b;
        }
    }

    svg{
        margin-right: 50px;;
    }
}
