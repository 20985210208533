@mixin container() {
    // max-width: 1150px;
    max-width: 1182px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

@mixin flexList() {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
}

@mixin list {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin divider {
    border-bottom: 0.5px solid rgba(181, 188, 203, 0.56);
    // border-bottom: 0.5px solid #B5BCCB;

    width: 100%;
    box-sizing: border-box;
    height: 1px;
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
    }

    ::-moz-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}

// Media queries
$S: 550px;
$M: 900px;
$L: 1150px;

@mixin MQ($canvas) {
    @if $canvas == S {
        @media only screen and (max-width: $S) {
            @content;
        }
    } @else if $canvas == M {
        @media only screen and (max-width: $M) {
            @content;
        }
    } @else if $canvas == L {
        @media only screen and (max-width: $L) {
            @content;
        }
    } @else if $canvas == SS {
        @media only screen and (min-width: $S) {
            @content;
        }
    } @else if $canvas == MM {
        @media only screen and (min-width: $M) {
            @content;
        }
    } @else if $canvas == LL {
        @media only screen and (min-width: $L) {
            @content;
        }
    }
}
